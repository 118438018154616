@import (reference) '../../semantic/src/definitions/collections/form.less';
@backgroundColor:      #155203;

html,
body {
  margin:0;
  padding:0;
  height:100%;
}

a, a:visited, a:active, a:hover {
  color: #295c3e;
}

body {
  font-family: helvetica;
  margin: 0;
  padding: 0;
  .main {
    min-width: 1024px;
    background: url(/source/parch.jpg) 20px 0;

    > .content {
      top:-20px;

      h1.center {
        margin-top: 150px;
        text-align: center;
      }
    }

    > .content, .top-menu .content {
      position: relative;
      padding: 10px;
      width: 1024px;
      margin: 0 auto;
    }

    .top-menu .content{
      background: none;
      padding-top:0;
    }
  }
}

#wrapper {
  height: 100%;
  position: relative;
  overflow-x: scroll;

  .main {
    padding-bottom:133px; /* Height of the footer element */
    min-height: 100%;
  }
}

footer {
  position: fixed;
  z-index: 100;
  text-align: right;
  color: rgba(255, 255, 255, 0.81);
  background: #18510b url('/source/bestnotes-small.png') 8px 6px no-repeat;
  background-size: 100px;
  width: 100%;
  height: 53px;
  bottom: 0px;
  left: 0;
  font-size: 11px;
  padding-top: 10px;
  padding-right: 15px;
  line-height: 18px;

  .release-time:hover {
      opacity: 1;
  }

  .release-time {
      position: absolute;
      top: -19px;
      right: 7px;
      color: #18510b;
      font-size: 13px;
      opacity: 0;
  }
}


.debug {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;

  > * {
    -webkit-flex: 1;
    flex: 1;
  }

  .context {
    font-size: 10px;
  }

  .query {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid lightgray;

    .query-text {
      margin-bottom: 0;
      margin-top: 0;
    }

    .query-name {
      margin-bottom: 5px;
      display: block;
      margin-left: 5px;
    }

    .responses {
      padding-left: 0;
      margin: 0;
      list-style-type: none;

      .response {
        padding: 2px;
      }
    }
    
    a {
      text-decoration: none;
      color: inherit;
      padding: 3px;
    }
  }
}

.shadow {
  -webkit-box-shadow: 0px 20px 35px -4px #867D56;
  -moz-box-shadow: 0px 20px 35px -4px #867D56;
  box-shadow: 0px 20px 35px -4px #867D56;
  height: 20px;
  position: relative;
  top: -60px;
}

.top-menu {
  height: 237px;
  border-top: 25px solid #09492a;
  background: url(/source/header-background.png) repeat-x;
  text-align: right;
  z-index: 1;
  position: relative; 

  .logo {
    width: 302px;
    height: 151px;
    position: absolute;
    left: 30px;
    top: 34px;
  }

  .tabs {
    font-size:14px;
    display: inline-block;
    position:relative;
    top:75px;
    a, a:visited, a:active {
      display:inline-block;
      font-weight:bold;

      margin:0 12px;
      padding: 2px 8px;
      border-radius: 3px;

      &:first-child {
        margin-left:0
      }

      &.active {
        background-color: rgba(9, 73, 42, 0.22);
      }

      &:last-child {
        margin-right:0
      }
    }
    a:hover {
      text-decoration:underline;
    }
  }

  .buttons {
    position:relative;
    top:100px;

    .enote {
      text-transform: none;
    }
  }

  .user {
    display: inline-block;
    position: absolute;
    top:-16px;
    right: 10px;
    color:white;
    span:after {
      margin-left:10px;
      content:'|';
    }
    &.logged-in {
      form.logout {
        display: inline-block;
        margin-left: 5px;

        input[type=submit] {
          border: none;
          background-color: transparent;
          cursor: pointer;
          color: #white;

          &:hover {
            background-color: fade(#669FEC, 20%);
          }
        }
      }
    }

    &.logged-out {
    }
  }
}

/* Couldn't get this working in semantic ul overrides yet */
.ui.message {
  margin: 0 auto 1em;
  top:-25px;
  max-width:1024px;
}


.top-flash {
  line-height: 34px;
  padding: 0 10px;
  border-bottom: 1px solid #efefef;

  .message {
    display: inline;
  }

  .detail {
    display: inline;
    font-size: 10px;
    line-height: normal;
    margin-bottom: 3px;
    margin-left: 5px;

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }

  .close {
    float: right;
    cursor: pointer;
    font-size: 1.2em;
    margin-right: 5px;
  }
}

.warning {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}


.progress() {
  background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 25%,
      rgba(0, 0, 0, 0.1) 25%,
      rgba(0, 0, 0, 0.1) 50%,
      transparent 50%
  ) top left fixed;
  background-size: 30px 30px;

  background-position: 0px 0px;

  -webkit-animation: barberpole 2s linear infinite;
  -moz-animation: barberpole 2s linear infinite;
  animation: barberpole 2s linear infinite;
}

@-webkit-keyframes barberpole {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 30px 0px;
  }
}

@keyframes barberpole {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 30px 0px;
  }
}

@-moz-keyframes barberpole {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 30px 0px;
  }
}

@accept-color: #F7F1CA;

.documents {
  .custom-segment{
    box-shadow: none;
    background-color: transparent;
    margin-top: 50px;
    .ui.inverted.dimmer {
      background-color: inherit;
    }
  }
  .your-documents .new-document {
    float:right;
  }
  .no-documents {
    a {
      text-decoration:underline;
    }
  }
  .document {
    cursor: pointer;
    &:hover {
      background-color: rgb(221, 223, 190);

      .button.delete {
        visibility: visible;
      }
    }

    .button.delete {
      visibility: hidden;
    }
  }
  td,th{
    white-space:nowrap;
    border-left:0 ! important;
  }
  td:first-child {
    width:100%;
    white-space:normal;
  }

  .ui.loading {
    padding-top: 200px;
    background-color: transparent;
  }
}

.enote {
  fieldset {
    background-color: #f2f1f1;
    margin-bottom:1.5em;
  }

  .document-name {
    text-align: right;

    label {
      margin-right: 10px;
    }

    .input {
      .label {
        text-align: left;
        width: 75px;
      }
    }
  }

  .ui.form input.enote-name {
    font-size:1.2em;
  }
  .query-response-editor, .document-tabs {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .query-response-editor {
    margin-bottom: 0;
  }

  .query-response-editor {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;

    .query-text {
      -webkit-flex: 1 100%;
      flex: 1 100%;
      border-color:   @backgroundColor;
      border-width:   1px; 
      border-style:   solid;
      background-color: #fff;
      padding:        10px;
      text-transform: uppercase;
      margin-bottom:  15px;
    }

    .button.accept {
      padding: 114px 0 0 0;
      -webkit-flex: 0 0 40px;
      flex: 0 0 40px;
    }

    .query, .response-editor {
      -webkit-flex: 1;
      flex: 1;
    }

    .query {

      .responses {
        background: white;
        border-radius: 3px;
        margin-right: 12px;
        border: 1px solid #155203;

        height: 253px;
        margin: 0;
        overflow-y: scroll;

        .response {
          border: none;
          position: relative;
          padding: 2px;
          padding-right: 42px;
          border-left: 3px solid transparent;

          &.editing {
            background-color: fade(@accept-color, 4%);

            &:after {
              content: 'editing';
            }
          }

          &.loading {
            .progress();
          }

          &.checked {
            a::after {
              content: '✔';
              margin-left: 8px;
            }
          }

          &.selected {
            background-color: #E7E2BE;
          }

          button.edit-response {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            border: none;
            background-color: @accept-color;
            cursor: pointer;
          }


          &:hover {
            button.edit-response {
              display: inline;
            }
          }

          a {
            width: 100%;
            text-align: left;
            font-size: 16px;
            text-decoration: none;
          }
        }
      }

      .buttons {
        margin-bottom: 5px;
        .selected {
          background-color: #E7E2BE;
        }
      }
    }

    .response-editor {
      position: relative;
      margin-left: 10px;
      order: 2;

      > .response-tabs {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .ui.tabular.menu {
          -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
        }

        .ui.tab {
          -webkit-flex: 1 100%;
          flex: 1 100%;
        }

        > .actions {
        }
      }

      .ui.tabular.menu a.edited {
        background-color: #FAF2E0;

        &.active {
          background-color: rgb(249, 226, 167);
        }
      }

      .response-text, .response-text-editor {
        background-color: white;
        outline: none;
        position: absolute !important;
        left: 10px;
        right: 10px;
        bottom: 10px;
        top: 10px;
        overflow: scroll;
      }

      .response-text-editor {
        box-shadow: inset 1px 1px 7px rgba(0, 0, 0, 0.1);
        border: 1px solid #afafaf;
        padding: 3px;
      }
    }
  }

  .actions {
    text-align: right;
    margin-top: 5px;

    .button:last-child {
      margin-right: 0;
    }

    .button.copied, .button.zeroclipboard-is-hover {
      background-color: #155203;
      color: #ffffff;
    }
  }

  .document-tabs {
    width: 740px;
    margin: 0 auto;
    margin-top: 15px;

    .document-outer {
      background-color: white;
      min-height: 45px;
      font-size: 12pt;
      font-family: times;
      width: 600px;
      margin: 0 auto;
    }

    .document {
      padding: 0;
      margin: 0;

      > .section {
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        &:hover, *:hover {
          background-color: @accept-color;
        }
      }
    }

    .debug {
      color: rgb(70, 45, 160);
      padding: 10px;

      .blocks {
        list-style-type: none;
        padding-left: 0;

        .block {
          .block-queries {
            padding-left: 0;
            margin-left: 5px;
            border-left: 1px solid lightgray;

            .block-query {
              padding-left: 5px;
            }
          }
        }
      }

      .found {
        font-weight: bold;
      }
      .before {
        color: gray;
      }
      .previous {
        color: gray;
        font-weight: bold;
      }
      .skipped {
        text-decoration: line-through;
      }
      .after {
        color: gray;
      }
    }
  }

  .ui.tabular.menu {
    text-align: right;
  }

  .ui.tab {
    width: auto !important;
  }
}

.login-page, .signup-page {
  margin: auto;
  width: 350px;
  h1 {
    margin-top: 20px;
    text-align: center;
  }
  .links {
    text-align: center;
    margin-top: 20px;

    a {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.login, .signup {
  text-align:right;
  input {
    width: 170px;
    margin-top: 5px;
  }
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.authoring-menu {
  position: absolute;
  top: 20px;
  right: 15px;
  a {
    background-color: #D3CEAA;
    padding: 8px;
    border-radius: 3px;
    border-bottom: 1px solid #aaa;
    color: white;
    text-decoration: none;
  }
}

hr {
  border: none;
  border-bottom: 1px solid rgb(231, 232, 209);
  margin: 20px;
}

.edit-query, .edit-block {
  .input, .field {
    > input[type=text], > textarea {
      font-family: courier !important;
    }
  }
}

.edit-lexicon {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 100vh;

  input[type=text], textarea {
    padding: 2px 8px !important;
  }
  
  > * {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 20px;
  }

  > *:first-child {
    -webkit-flex: 0 0 385px;
    flex: 0 0 385px;
    margin-left: 0;
  }
}

.predicant-search {
  margin: 10px;

  .results.menu {
    max-height: calc(~"100vh - 474px");
    min-height: 700px;
    overflow-y: scroll;
    width: auto;
  }
}

.edit-query, .edit-block, .edit-lexicon {
  ul {
    list-style-type: none;
    padding-left: 0;
    
    & > li {
      padding: 10px;
    }
  }

  .ui.form textarea {
    height: auto;
    min-height: auto;
    max-height: auto;
  }

  .buttons {
    button {
      margin-bottom: 5px;
    }
  }

  .btn-group {
    .btn, .dropdown-menu {
      min-width: 100px;
      background-color: #E7E8D1;
      box-shadow: 2px 2px 5px #CFD0AF;
      cursor: pointer;
    }

    .dropdown-menu {
      margin-top: 4px;

      li {
        padding: 3px 5px;

        &:hover {
          background-color: rgb(221, 223, 190);
        }

        a {
          text-decoration: none;
          color: #6e6631;
          outline: none;
        }
      }
    }
  }

  button {
    cursor: pointer;
    border: none;
    background-color: rgba(231, 232, 209, 1);
    font-size: 15px;
    box-shadow: 2px 2px 5px #CFD0AF;
    padding: 5px 10px;
    outline: none;
    color: rgb(110, 102, 49);
    margin-bottom: 5px;

    &.remove {
      background-color: inherit;
      box-shadow: none;
      position: absolute;
      right: 0;
    }
  }

  .editor {
    &:extend(.ui.form textarea);
    &:extend(.edit-lexicon .ui.form textarea);

    padding: 2px 8px;
  }

  .editor > *:last-child {
    margin-bottom: 0px;
  }
  .editor > *:first-child {
    margin-top: 0px;
  }

  .medium-editor-placeholder:after {
    top: 6px;
    left: 3px;
  }

  textarea {
    width: 400px;
    vertical-align: top;
  }

  label {
    min-width: 160px;
    display: inline-block;
    margin-top: 4px;
    font-weight: bold;
    color: rgb(94, 90, 67);
  }

  h1, h2, h3, h4 {
    color: rgb(94, 90, 67);
  }

  .action-add-blocks, .action-set-blocks {
    li {
      cursor: move;
    }
  }

  .responses, .blocks-queries {
    > .ui.vertical.menu {
      width: auto;

      > .item {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        
        > .header > a {
          font-size: 18px;
          background-color: inherit;
        }

        > .menu {
          padding-bottom: 5px;
        }
      }

      .menu {
        padding-left: 8px;

        > .item {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .item.no-query {
      padding-right: 21px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .item .header {
      white-space: nowrap;
    }

    .item > .header {
      a {
        padding: 3px;
        font-size: 12px;
      }
      i.icon {
        font-size: 12px;
      }
    }

    .item.active > .header a {
      font-weight: bold;
      background-color: rgba(110, 109, 88, 0.22);
    }

    & > ol, & > ul {
      list-style-type: none;
      padding-left: 0;
      padding: 5px;

      & > li {
        margin: 20px 0;
        background-color: rgba(231, 232, 209, 0.25);
        position: relative;
        box-shadow: 1px 1px 10px rgb(231, 232, 209);

        &[draggable] {
          cursor: move;

          &:hover:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            bottom: 3px;
            width: 0px;
            border-left: 4px dotted rgba(231, 232, 209, 0.86);
            border-right: 4px dotted rgba(231, 232, 209, 0.86);
          }
        }

        &.selected {
        }

        &.placeholder {
          background-color: transparent;
          box-shadow: none;
        }

        a:visited {
          color: #71672E;
        }

        a {
          color: inherit;
        }

        label {
          min-width: 130px;
        }
      }

    }
  }

  li {
    > .buttons {
      position: absolute;
      right: 7px;
      bottom: 5px;

      &.top {
        bottom: none;
        top: 7px;
      }

      button {
        font-size: 9px;
        margin-right: 0;
        margin-left: 10px;
        border: none;
        padding: 2px 4px;
        cursor: pointer;
      }
    }
  }

  .clipboard {
    .ui.menu {
      width: auto;

      .item {
        .remove {
          visibility: hidden;
        }

        &:hover {
          .remove {
            visibility: visible;
          }
        }
      }
    }
  }

  .blocks-queries {
    min-width: 220px;

    .buttons {
      margin-bottom: 15px;
    }

    .block-query-menu {
      max-height: calc(~"100vh - 440px");
      min-height: 700px;
      overflow-y: scroll;
    }
  }

  .responses {
    .response-editor {
      display: -webkit-flex;
      display: flex;
      align-items: flex-start;
      margin-top: 10px;

      > .ui.vertical.menu {
        width: 140px;
        min-width: 140px;
        max-width: 140px;
      }

      > *:first-child {
        margin-left: 0;
      }

      > *:last-child {
        margin-right: 0;
      }

      > * {
        margin-left: 5px;
        margin-right: 5px;
      }

      .selected-response {
        -webkit-flex: 1 auto;
        flex: 1 auto;

        > li {
          position: relative;
        }
      }
    }
  }

  .selected-predicant, .edit-query, .edit-block {
    margin: 10px;
  }

  .selected-predicant {

    .predicant-usages {
      display: -webkit-flex;
      display: flex;
      margin-top: 30px;

      .menu {
        width: auto;
      }

      .predicant-usages-queries {
        margin-right: 10px;
        -webkit-flex: 1;
        flex: 1;
      }

      .predicant-usages-responses {
        margin-left: 10px;
        -webkit-flex: 1;
        flex: 1;
      }
    }
  }

  .field {
    .ui.vertical.menu {
      width: auto;
    }
  }
}

.edit-query {
  .actions {
    .menu {
      > .item {
        padding: 9px;
      }
    }
  }

  .menu {
    > .item {
      > .remove {
        display: none;
        position: absolute;
        top: 2px;

        padding: 3px 9px;
        border-top-left-radius: 0;

        &.small {
          bottom: 2px;
          border-bottom-left-radius: 0;
        }

        &:not(.small) {
          border-bottom-right-radius: 0;
        }
      }

      &:hover {
        > .remove {
          display: inline-block;
        }
      }

      &:not(:first-child) {
        > .remove {
          border-top-right-radius: 0;
        }
      }

      &:not(:last-child) {
        > .remove {
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  div.predicants {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  .two.fields {
    .item-select {
      width:100%;
    }
  }

  .menu .item {
    padding: 2px 4px;
  }

  .item-select {
    display: inline-block;

    input[type=text] {
      &::-webkit-input-placeholder { color: rgba(94, 90, 67, 0.28); }
      &::-moz-placeholder { color: rgba(94, 90, 67, 0.28); }
      outline: none;
    }

    div.select-list {
      position: relative;

      .menu {
        position: absolute;
        max-height: 200px;
        overflow-y: scroll;
        box-shadow: 0px 0px 0px 1px #155203, 0px 1px 2px 0 rgba(0, 0, 0, 0.05), 2px 2px 20px rgba(0, 0, 0, 0.1);
        z-index: 1;
        top: 6px;

        &.hidden {
          display: none;
        }

        .item {
          .selected {
            position: absolute;
            right: 10px;
            margin-left: 20px;
          }
        }
      }

      ol:not(.show) {
        display: none;
      }
    }
  }

  li {
    > button.remove {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background-color: inherit;
      color: rgb(180, 182, 137);
      outline: none;
      font-size: 16px;
      padding: 0;
    }

    &:hover {
      > button.remove {
        display: inline-block;
      }
    }
  }
}

.admin {
  display: -webkit-flex;
  display: flex;

  .search {
    -webkit-flex: 0 0 226px;
    flex: 0 0 226px;

    .ui.icon.input input {
      padding-right: 48px !important;
    }
  }

  .user {
    -webkit-flex: 0 1 800px;
    flex: 0 1 800px;

    h2 {
      height: 40px;
    }

    input[type=text].token-link {
      overflow: hidden;
      font-size: 13px;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    .copy-token-link {
      white-space: nowrap;
      min-width: 130px;
    }
  }
}

/* minimum bootstrap for dropdown */
.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
}

.open {
  > .dropdown-menu {
    display: block;
  }
}

.btn-group {
  display: inline-block;
}
